import React, { useState } from "react";
import { Routes, Route} from "react-router-dom";


import MaintenancePage from "../MaintenancePage/maintenancePage";
import Home from "../Pages/Home/home";
import Tarifs from "../Pages/Tarifs/tarifs";
import About from "../Pages/About/about";
import Contact from "../Pages/Contact/contact";
import Sophrologie from "../Pages/Sophrologie/sophrologie";
import Accompagnements from "../Pages/Accompagnements/accompagnements";
import Error from "../Pages/Error/error";
import PrivacyPolicy from "../Documents/privacyPolicy";
import Legacy from "../Documents/legacy";
// import AdminPage from "../Admin/adminPage";
// import LoginPage from "../Admin/loginPage";
// import PrivateRoute from "../PrivateRoute/privateRoute";  // Garde cet import

export default function App() {
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  // Activer/désactiver le mode de maintenance
  const toggleMaintenanceMode = () => {
    setMaintenanceMode((prevMode) => !prevMode);
  };

  return (
    <Routes>
      {maintenanceMode ? (
        <Route path="*" element={<MaintenancePage toggleMaintenanceMode={toggleMaintenanceMode} />} />
      ) : (
        <>
        <Route path="/" element={<Home />} />
       {/* <Route path="/login" element={<LoginPage />} />   Nouvelle route login */}
        {/*<Route path="/admin" element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          } />*/}
          <Route path="/accompagnements" element={<Accompagnements />} />
          <Route path="/tarifs" element={<Tarifs />} />
          <Route path="/sophrologie" element={<Sophrologie />} />
          <Route path="/a-propos" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mentions-légales" element={<Legacy />} />
          <Route path="/politique-de-confidentialité" element={<PrivacyPolicy />} />
          <Route path="*" element={<Error />} />
         
        </>
      )}
    </Routes>
  );
}
