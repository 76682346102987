// Fonction pour valider l'adresse e-mail avec une regex
export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

  
// ✅ Fonction pour valider le nom avec accents
export const isValidName = (name) => {
  const nameRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/u;
  return nameRegex.test(name);
};
