import React, { useEffect, useState } from 'react'
import LoaderSpinner from '../LoaderSpinner/loaderSpinner'
const PrivacyPolicy = () => {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    document.title =
      'MaSophroFabienne - Politique de confidentialité et protection des données'

    // Exemple d'utilisation : simulation d'un chargement asynchrone pendant 3 secondes
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])
  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <iframe
      src="./files/Politique_de_Confidentialité.html"
      title="Politique de confidentialité"
      style={{
        width: '100%',
        height: '500px',
        border: 'none',
      }}
      className="doc"
    />
  )
}

export default PrivacyPolicy
