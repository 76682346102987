import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import LinkedinLogo from '../../../assets/LinkedIn.webp'
import FaceBookLogo from '../../../assets/facebook.webp'
import InstagramLogo from '../../../assets/Instagram.webp'
import LogoSyndicat from '../../../assets/logoSyndicat.webp'
import LogoSFS from '../../../assets/logo-sfs.webp'
import '../../../styles/sass/layouts/_footer.scss'

export default function Footer() {
  // const [showEmail, setShowEmail] = useState(false)
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)

  // const toggleEmail = () => {
  //   setShowEmail(!showEmail)
  // }
  const togglePhoneNumber = () => {
    setShowPhoneNumber(!showPhoneNumber)
  }

  const phoneNumber = '06.07.25.72.24';

  const handlePhoneNumberClick = () => {
    if (isMobile()) {
      window.location.href = `tel:${phoneNumber.replace(/\./g, '')}`;
    }
  };

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };


  const getCurrentYear = () => {
    return new Date().getFullYear()
  }
  const currentYear = getCurrentYear()

  return (
    <footer className="container-fluid footer mt-5">
      {/* Section: Links  */}
      <div className="border-bottom">
        <div className="container-fluid text-md-start mt-5">
          {/* Grid row */}
          <div className="row justify-content-center mt-3">
            {/* Grid column */}
            <div className="col-3 mx-auto mb-5 logo-syndicat-container">
              {/* Content */}
              <h6 className="text-uppercase fw-bold d-none d-lg-block">
                Syndicat des Sophrologues Professionnels
              </h6>
              <a
                href="https://www.syndicat-sophrologues-professionnels.fr/nguyen-mat-barre-fabienne.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="logo-syndicat"
                  src={LogoSyndicat}
                  alt="logo syndicat des sophrologues professionnels"
                />
              </a>
              <h6 className="text-uppercase fw-bold mb-2 mt-2 d-none d-lg-block">
                Société Française de Sophrologie
              </h6>
              <a
                href="https://www.sophrologie-francaise.com/directory/nguyen-mat-barre-fabienne-sophrologue"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className=" logo-syndicat"
                  src={LogoSFS}
                  alt="logo syndicat de la sophrologie française"
                />
              </a>
            </div>
            {/* Grid column */}

            {/* Grid column */}
            <div className="col-lg-3 col-xl-4  mx-auto mb-3 text-center">
              {/* Links */}
              <Link to="/contact">
                <h6 className="text-uppercase fw-bold ">Contact</h6>
              </Link>

              <span className="links-text">Le Perreux sur Marne</span>
              <div className="email-container mt-0">
                <FontAwesomeIcon icon={faEnvelope} />
                <span className="links-text">masophrofabienne@gmail.com</span>
              </div>
              <div
                className="tel-container-footer mt-0"
                onClick={togglePhoneNumber}
              >
                <FontAwesomeIcon
                  className="icon-tel"
                  icon={faPhoneAlt}
                  flip="vertical"
                  fa-rotate-right="true"
                />
                <span className="links-text" onClick={handlePhoneNumberClick}>
                  {showPhoneNumber ? phoneNumber : ' Afficher le numéro'}
                </span>
              </div>
            </div>
            {/* Grid column */}

            {/* Grid column */}
            <div className="col-lg-3 col-xl-4  mx-auto mb-3  text-center">
              {/* Links */}
              <h6 className="text-uppercase fw-bold">Liens utiles</h6>
              <p className="links-text">
                <Link to="/mentions-légales" className="text-reset">
                  Mentions Légales
                </Link>
              </p>
              <p className="links-text">
                <Link to="/politique-de-confidentialité" className="text-reset">
                  Politique de confidentialité
                </Link>
              </p>

              <p className="links-text">
                <Link to="https://devfashion.fr" className="text-reset" target="_blank"
                  rel="noopener noreferrer">
                  VanGitParis{' '}
                </Link>
              </p>
              <p className="links-text-recaptcha">Ce site est protégé par reCAPTCHA et Google.
                <Link href="https://policies.google.com/privacy">Politique de confidentialité  et </Link>
               <Link href="https://policies.google.com/terms">Conditions d'utilisation</Link>
                s'appliquent.   
              </p>
            </div>
            {/* Grid column */}

            {/* Section: Social media */}
            <div className="d-flex justify-content-center justify-content-lg-center p-2">
              {/* Left */}
              <div className="d-flex align-items-center">
                <a
                  href="https://www.linkedin.com/in/fabienne-nguyen-mat-barre/"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width="48" src={LinkedinLogo} alt="Linkedin logo" />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61553666303789"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width="48" src={FaceBookLogo} alt="Facebook logo" />
                </a>
                <a
                  href="https://www.instagram.com/masophrofabienne"
                  className="me-4 text-reset"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img width="48" src={InstagramLogo} alt="Instagram logo" />
                </a>
              </div>
              {/* Right */}
            </div>
            <div className="me-5 d-none d-lg-block"></div>
            {/* Section: Social media */}
          </div>
          {/* Grid row */}
        </div>
      </div>
      {/* Section: Links  */}

      {/* Copyright */}
      <div className="text-center p-1">© {currentYear} MaSophroFabienne </div>
      <div className="text-center p-1 fs-custom-siren">Siren : 982 684 441</div>
      {/* Copyright */}
    </footer>
  )
}
