import React, { useState, useEffect } from 'react'
import Fabienne from '../../../assets/Profil-Fabienne-removebg-preview.png'
import '../../../styles/sass/pages/_about.scss'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import LoaderSpinner from '../../LoaderSpinner/loaderSpinner'

export default function About() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title =
      'MaSophroFabienne -  Découvrez Fabienne, Sophrologue à Le Perreux-sur-Marne | Accompagnement personnalisé pour retrouver l\'harmonie intérieure.'

    // Exemple d'utilisation : simulation d'un chargement asynchrone pendant 3 secondes
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <main className="container-fluid responsive-sm main-content">
      <section>
        <Row className="container-fluid">
          <Col>
            <h1 className="h1-about text-center">À Propos de moi</h1>
          </Col>
        </Row>
      </section>

      <div className="profil-content-about container-fluid">
        <div>
          <img
            className=" img-profil-about"
            src={Fabienne}
            alt="Fabienne, sophrologue"
          />
        </div>
        <div className="profil-text-about">
          <h2 className="mt-0 sub-title-about text-center">
            Je suis Fabienne, Sophrologue Indépendante (E.I.)
          </h2>
          <p className="mt-2 text-center text-about">
            Après plus de 30 années d’activité et d’attention portée à l’autre,{' '}
            <br />
            MaSophroFabienne s’inscrit comme une continuité <br />
            pour accompagner ceux qui en ont besoin <br />à retrouver une
            qualité de vie.
          </p>
          <p className="mt-3 text-center text-about">
            <strong>
              Transmettre une méthode éprouvée et mener à l’autonomie <br />
              pour répondre à ses propres besoins est plein de sens pour moi.
            </strong>
          </p>
          <Link to="/contact" className="btn cta-link">
            Contactez-moi
          </Link>
        </div>
      </div>

      <Container className="container-fluid p-5 background-pink-container section">
        <Row className=" mb-0">
          <h3 className="sub-title-about text-center">Mon Parcours</h3>
          <p className="text-about">
            Je fais le choix d’intervenir plus particulièrement auprès de la
            personne âgée <br />
            pour laquelle j’ai une grande tendresse et un profond respect,{' '}
            <br /> dans la considération qu’elle est digne de mon attention
            quelque soit sa situation.
          </p>
        </Row>
      </Container>
      <Container className="container-fluid p-5 section">
        <Row className=" mb-0 ">
          <h3 className="sub-title-about text-center ">Ma Formation</h3>
          <div className="text-about mb-0 text-center ">
            <p>
              Diplômée Sophrologue après 2 années à l’Ecole Supérieure de
              Sophrologie Appliquée - ESSA.
              <br /> Formation inscrite au RNCP par La Société Française de
              Sophrologie.
            </p>
          </div>
        </Row>
      </Container>
      <Container className="container-fluid p-5 background-container section">
        <Row className="mb-0">
          <h3 className="sub-title-about text-center">Mes Spécialisations</h3>

          <nav className="text-about">
            <ul>
              <li className="li-about">
                Spécialisation en sophrologie appliquée à l’accompagnement de la{' '}
                <br />
                maladie d’Alzheimer et des aidants - ESSA{' '}
              </li>
              <li className="li-about">
                {' '}
                Spécialisation en sophrologie appliquée à l’accompagnement des
                émotions - ESSA
              </li>
              <li className="li-about">
                Formation au protocole spécifique pour la prise en charge de{' '}
                <br />
                l’acouphène chronique par la sophrologie - Patricia Grévin{' '}
              </li>
              <li className="li-about">
                Prise en charge des Troubles de l'audition par la sophrologie :{' '}
                <br />
                hyperacousie et vertiges dont la Maladie de Ménière{' '} - Patricia Grévin
              </li>
              <li className="li-about">
                Sophrologie et aide aux aidants familiaux - I.S.S.P
                </li>
                <li className="li-about">
                {' '}
                Spécialisation Sophrologie et maladie de Parkinson par  <br />Véronique Bouyer - Sophrologue et formatrice chez France Parkinson.
              </li>
            </ul>
          </nav>
        </Row>
      </Container>
      <Container className="container-fluid p-5 section">
        <Row className="mb-0">
          <h3 className="sub-title-about text-center">Mes Compétences</h3>
          <div className=" mb-0 text-about ">
            <p className="">
              Professionnelle de la relation d’aide. <br />
            </p>
            <p>
              <strong>Mes qualités :</strong>
            </p>
          </div>
          <nav className="text-about">
            <ul>
              <li className="li-about">
                {' '}
                Écoute active, bienveillante et empathique <br />
              </li>
              <li className="li-about">
                Accueil sans jugement, ni a priori centré sur la personne{' '}
                <br />
              </li>
              <li className="li-about">
                {' '}
                Adaptabilité <br />
              </li>
            </ul>
          </nav>
          <div>
            <p className="text-about">
              <strong>Regard positif inconditionnel </strong>
              <br />
            </p>
          </div>
        </Row>
      </Container>
      <Container className="container-fluid p-5 background-pink-container section">
        <Row className="mb-0">
          <h3 className="sub-title-about text-center">
            Mon expérience dans un Ehpad du groupe Almage
          </h3>
          <div className="text-about mb-0 text-center ">
            <p className="text-about">
              <strong>
                L’animation d’un atelier de sophrologie auprès d’un groupe de
                résidents <br />
                en stade léger à modéré de la maladie d'Alzheimer, <br />m’a permis
                d’observer un mieux être visible pendant la séance :{' '}
              </strong>
            </p>
          </div>
          <nav className="text-about">
            <ul className='pl-custom'>
              <li className="li-about"> Les corps s’animent, se déploient. </li>
              <li className="li-about">
                {' '}
                Il y a une meilleure appréhension des capacités respiratoires.{' '}
              </li>

              <li className="li-about">
                {' '}
                L’anxiété diminue. Les participants, apaisés, s’engagent
                pleinement <br />dans l’expérience.
              </li>
              <li className="li-about">
                {' '}
                Il y a des sourires, des partages d’émotions et de ressentis
                corporels.
              </li>
              <li className="li-about">
                {' '}
                Ils sont fiers de ce qu’ils ont réalisé et de pouvoir se
                raconter, encore, <br className="d-block d-md-none" /> quelque soit le mode utilisé : <br className="d-block d-md-none" />verbal,
                comportemental, pictural.
              </li>
            </ul>
          </nav>
        </Row>
      </Container>
    </main>
  )
}
