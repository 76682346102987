import React, { useEffect, useState } from 'react'
import LoaderSpinner from '../LoaderSpinner/loaderSpinner'
const Legacy = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
  
    document.title = 'MaSophroFabienne - Mentions Légales'

  
   // Exemple d'utilisation : simulation d'un chargement asynchrone pendant 3 secondes
   const timer = setTimeout(() => {
    setIsLoading(false)
  }, 2000)
    return () => {
      
      clearTimeout(timer)
    };
   


  }, [])
  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <iframe
      src="./files/mentions-légales.html"
      title="Mentions Légales"
      style={{
        width: '100%',
        height: '500px',
        border: 'none',
      }}
      className="doc"
    />
  )
}

export default Legacy
