import React, { useState, useEffect } from 'react'
import { Form, Col, Container, Alert } from 'react-bootstrap'
import { submitForm } from '../../../services/apicall'
import { isValidName, isValidEmail } from '../../../modelisation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faAngleDown } from '@fortawesome/free-solid-svg-icons'

import '../../../styles/sass/pages/_contact.scss'
import LoaderSpinner from '../../LoaderSpinner/loaderSpinner'

export default function ContactForm() {
  const [isLoading, setIsLoading] = useState(true)
  const [showPhoneNumber, setShowPhoneNumber] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    reason: '',
  })
  const executeRecaptcha = () => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Lfsz7sqAAAAANRVxPqcozr9Bk8fSREASQptSnKV', { action: 'submit' }).then((token) => {
          setFormData((prevData) => ({
            ...prevData,
            recaptcha: token,
          }));
        });
      });
      
    } else {
      console.error("reCAPTCHA n'est pas chargé.");
    }
  };
  
  useEffect(() => {
    document.title =
      'MaSophroFabienne - Contact | Prenez contact avec Fabienne, Sophrologue à Le Perreux-sur-Marne'
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const togglePhoneNumber = () => {
    setShowPhoneNumber(!showPhoneNumber)
  }

  const showSuccessAndResetForm = () => {
    setShowSuccessMessage(true)
    setErrorMessage('')
    setFormData({ name: '', email: '', message: '', reason: '' })

    setTimeout(() => {
      setShowSuccessMessage(false)
    }, 5000)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrorMessage('')

    if (!isValidEmail(formData.email)) {
      setErrorMessage('Adresse e-mail invalide')
      return
    }

    if (!isValidName(formData.name)) {
      setErrorMessage('Nom invalide')
      return
    }

    try {
      const response = await submitForm({ ...formData })
      if (response) {
        showSuccessAndResetForm()
      } else {
        throw new Error('Erreur lors de la soumission du formulaire')
      }
    } catch (error) {
      console.error('Erreur lors de la soumission :', error.message)
      setErrorMessage(
        "Une erreur s'est produite lors de l'envoi du formulaire. Veuillez réessayer.",
      )
    }
  }

  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <main className="container-fluid responsive-sm">
      <section className="contact-form-container main-content">
        <Container className="mb-0">
          <h1 className="text-center h1-contact">Contactez-moi</h1>
          <p className="text-center d-none d-md-block">
            N'hésitez pas à m’appeler ou m’envoyer un message <br /> pour fixer
            un rendez-vous ou pour plus d’informations.
          </p>
          <div className="tel-container" onClick={togglePhoneNumber}>
            <FontAwesomeIcon
              className="icon-tel"
              icon={faPhoneAlt}
              flip="vertical"
            />
            <span>
              {showPhoneNumber ? '06.07.25.72.24' : 'Afficher le numéro'}
            </span>
          </div>
        </Container>

        <Col className="col-lg-6 col-md-6 custom-col mt-0">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formReason">
              <Form.Label>Que souhaitez-vous faire ?</Form.Label>
              <div className="select-wrapper">
                <Form.Control
                  as="select"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                >
                  <option value="">Sélectionnez une option</option>
                  <option value="demande-devis">Demander un devis</option>
                  <option value="reserver-seance">Réserver une séance</option>
                  <option value="demande-info">
                    Demander des informations
                  </option>
                </Form.Control>
                <FontAwesomeIcon
                  className="angle-down-icon"
                  icon={faAngleDown}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formName">
              <Form.Label>Entrez votre nom</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom"
                name="name"
                value={formData.name}
                onChange={handleChange}
                autoComplete="name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Entrez votre adresse e-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="e-mail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Entrez votre message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* ✅ Champ caché pour le token reCAPTCHA */}
            <input type="hidden" id="recaptcha-token" name="recaptcha" />

            <div className="submit-btn-container d-flex flex-column">
              <button className="cta-link mt-5 d-flex btn" type="submit" onClick={() => executeRecaptcha('contact')}>
                Envoyer
              </button>

              {showSuccessMessage && (
                <Alert
                  variant="success"
                  className="success-msg mt-3"
                  dismissible
                >
                  Votre message a été envoyé avec succès !
                </Alert>
              )}
            </div>

            {errorMessage && !showSuccessMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </Form>
        </Col>
      </section>
    </main>
  )
}
