import React, { useState, useEffect } from 'react'

import Profil from '../../../assets/Profil.webp'
import { Link } from 'react-router-dom'
import ImageHome1 from '../../../assets/Img-Home-1.webp'
import '../../../styles/sass/pages/_home.scss'
import LoaderSpinner from '../../LoaderSpinner/loaderSpinner'
import ReviewCarousel from '../../Reviews/reviews'
import { Col, Row } from 'react-bootstrap'

export default function Home() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title =
      'MaSophroFabienne - Accueil | Sophrologie personnalisée avec Fabienne à Le Perreux-sur-Marne. Déplacements et Visio disponibles.'

    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <main className="container-fluid">
      <section className="container-fluid main-content">
        <Row className="container-fluid">
          <Col>
            <h1 className="text-center h1-home"> MaSophroFabienne </h1>
          </Col>
        </Row>
      </section>

      <section className="container-fluid profil-content">
        <div className="container">
          <div className="row align-items-center">
            <div className="text-center mb-3 mb-md-0 col-sm-12 col-md-6">
              <img
                className="img-profil-home img-fluid"
                src={Profil}
                alt="Fabienne, sophrologue au Perreux-sur-Marne"
                title="Sophrologue Fabienne au Perreux-sur-Marne"
              />
            </div>
            <div className="col-md-6">
              <div className="profile-text">
                <p>
                  <strong>Sophrologue</strong> diplômée de l’ESSA. <br />
                  École Supérieure de Sophrologie Appliquée
                </p>
                <p className="d-block d-md-none">
                  Spécialisée dans l’accompagnement de la {' '}
                  <strong>maladie d’Alzheimer</strong>, de la{' '}
                  <strong>maladie de Parkinson </strong> et <strong> des aidants</strong> , de la{' '}
                  <strong>personne âgée</strong>, dans l’accompagnement des{' '}
                  <strong>émotions</strong>, et des <strong>troubles auditifs : acouphènes</strong> et{' '}
                  <strong>vertiges</strong> dont la{' '}
                  <strong>maladie de Ménière</strong>.
                </p>
                <p className="d-none d-md-block">
                  Spécialisée dans l’accompagnement de la{' '}
                  <strong>maladie <br />d’Alzheimer</strong>, de la{' '}
                  <strong>maladie de Parkinson </strong> et <strong>des aidants</strong> , <br />
                  de la <strong>personne âgée</strong>, <br />
                  dans l’accompagnement des <strong>émotions,
                  </strong> et des <strong>troubles <br />auditifs : acouphènes</strong> et{' '}
                  <strong>vertiges</strong> dont la{' '}
                  <strong>maladie de Ménière</strong>.
                </p>
                <p>
                  Membre de la Société Française de Sophrologie <br /> et du
                  Syndicat des Sophrologues Professionnels
                </p>
                <div className="d-flex justify-content-center justify-content-md-start">
                  <Link to="/contact" className="btn cta-link">
                    Contactez-moi
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid tagline-container">
        <h2 className="text-center tagline">
          Pour une dynamique positive au quotidien
        </h2>
      </section>

      <section className="container-fluid text-center p-5 background-pink-container">
        <h3 className="title-section">La Sophrologie en quelques mots ...</h3>
        <p className="text-section d-block d-md-none">
          La sophrologie, c’est une méthode basée sur{' '}
          <strong>
            des techniques de respiration, de relaxation, et de visualisation
            positive.
          </strong>{' '}
          <br /> Elle aide à retrouver <strong>une qualité de vie</strong> dans
          une harmonie du corps et de l’esprit.
        </p>
        <p className="text-section d-none d-md-block">
          La sophrologie, c’est une méthode basée sur{' '}
          <strong>
            des techniques de respiration, de relaxation, et de visualisation
            positive.
          </strong>{' '}
          <br /> Elle aide à retrouver <strong>une qualité de vie</strong> dans
          une harmonie du corps et de l’esprit.
        </p>
        <p className="text-section">
          Elle permet de potentialiser ses propres ressources pour s’adapter aux
          évènements de vie et répondre à ses besoins du moment :
        </p>
        <p className="text-section d-none d-md-block">
          {' '}
          <strong>
            réduire l'anxiété, prendre du recul, accueillir ses émotions…
          </strong>{' '}
          <br /> et{' '}
          <strong>
            {' '}
            s'inscrire dans la dynamique positive d'un agir sur soi pour un
            mieux-être.
          </strong>
        </p>
        <p className="text-section d-block d-md-none">
          {' '}
          <strong>
            réduire l'anxiété, prendre du recul, accueillir ses émotions…
          </strong>{' '}
          et{' '}
          <strong>
            {' '}
            s'inscrire dans la dynamique positive d'un agir sur soi pour un
            mieux-être.
          </strong>
        </p>
        <p className="text-section">
          Ainsi, elle accompagne à entretenir{' '}
          <strong>
            un nouveau rapport à la vie plus confiant, plus serein.
          </strong>
        </p>
        <p className="text-section">
          L'idée est d'amener à s'approprier la méthode pour{' '}
          <strong> devenir autonome.</strong>
        </p>
        <div className="d-flex justify-content-center">
          <Link to="/accompagnements" className="btn link-article">
            Découvrir
          </Link>
        </div>
      </section>

      <div className="container-image-home-1 mt-3 mb-3">
        <img className="image-home-1" src={ImageHome1} alt="" />
      </div>

      <section className="container text-center p-5 background-container mb-5">
        <h3 className="title-section">Ma Conviction</h3>
        <p className="text-section">
          La pratique peut <strong>s’adapter à chacun</strong> selon ses propres
          besoins et possibilités.
        </p>
        <p className="text-section d-block d-md-none">
          {' '}
          Chacun peut <strong>
            l’intégrer facilement dans son quotidien
          </strong>{' '}
          pour <strong>améliorer sa qualité de vie.</strong>
        </p>
        <p className="text-section d-none d-md-block">
          {' '}
          Chacun peut <strong>
            l’intégrer facilement dans son quotidien
          </strong>{' '}
          <br /> pour <strong>améliorer sa qualité de vie.</strong>
        </p>
        <div className="d-flex justify-content-center">
          <Link to="/sophrologie" className="btn cta-link-pink">
            En savoir plus
          </Link>
        </div>
      </section>

      {/* Section "À Propos" */}
      <section className="container text-center p-5 background-pink-container mb-5">
        <h3 className="title-section">À Propos de moi</h3>
        <p className="text-center">
          <strong>Bienvenue ! </strong>
        </p>
        <p className="text-section">
          Je suis Fabienne, et <strong>je vous accompagne</strong> dans votre
          cheminement vers <strong>le bien-être</strong> <br /> et{' '}
          <strong>l’harmonie intérieure.</strong> <br /> En tant que{' '}
          <strong>sophrologue passionnée,</strong> <br />{' '}
          <strong>je crois </strong>profondément en{' '}
          <strong>la capacité de chacun</strong> à trouver la{' '}
          <strong>paix intérieure</strong> <br /> et à{' '}
          <strong>surmonter les défis</strong> de la vie{' '}
          <strong>avec confiance.</strong>
        </p>
        <p className="text-section">
          Mon <strong>approche holistique et empathique</strong> vise à vous
          transmettre <br /> les <strong>techniques les plus adaptées</strong> à
          vous-même et à votre situation, <br /> pour{' '}
          <strong>cultiver un sentiment d’apaisement</strong> et de{' '}
          <strong>sérénité</strong> dans votre quotidien.
        </p>
        <p className="text-section">
          Mon <strong>accueil bienveillant,</strong> centré sur la personne que
          vous êtes, <br /> <strong>sans jugement ni a priori,</strong> me
          permet d’être au plus proche de vos préoccupations <br /> et de{' '}
          <strong>travailler avec vous pour progresser</strong> vers l’objectif
          que vous vous êtes fixé.
        </p>
        <p className="text-section">
          Que vous soyez confronté à des{' '}
          <strong>défis émotionnels, des difficultés personnelles</strong>{' '}
          <br /> ou simplement à la{' '}
          <strong>
            recherche d’un espace pour vous re-connecter avec vous-mêmes,
          </strong>{' '}
          <br /> <strong>je suis là </strong> pour vous aider à{' '}
          <strong>retrouver un équilibre de vie.</strong>
        </p>
        <div className="d-flex justify-content-center">
          <Link to="/a-propos" className="btn link-article">
            Découvrir
          </Link>
        </div>
      </section>

      {/* Section "Tarifs" */}
      <section className="container text-center p-5 background-container mb-5">
        <h3 className="title-section">Tarifs</h3>
        <p className="text-section ">
          <strong>
            Vous êtes à la recherche de solutions pour améliorer votre bien-être
            et votre qualité de vie ?
          </strong>
        </p>
        <p className="text-section mb-0">
          Consultez mes services pour découvrir les différentes options
          personnalisées que je peux vous proposer.
        </p>
        <p className="text-section">
          Que vous recherchiez une <strong>séance individuelle</strong> pour{' '}
          <strong>un accompagnement sur mesure,</strong> <br />{' '}
          <strong>une séance en binôme </strong> pour renforcer vos liens
          relationnels, <br /> ou <strong>une séance en groupe </strong> pour
          partager une expérience enrichissante avec d'autres, <br />{' '}
          <strong>je suis là </strong> pour vous aider à{' '}
          <strong>trouver la solution </strong>qui{' '}
          <strong>vous convient</strong> le mieux.
        </p>
        <div className="d-flex justify-content-center">
          <Link to="/tarifs" className="btn cta-link-pink">
            En savoir plus
          </Link>
          </div>
        </section>
        <ReviewCarousel/>
    </main>
  )
}
