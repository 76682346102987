import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import logo from '../../../assets/logo.webp'
import '../../../styles/sass/layouts/_header.scss'

export default function Header() {
  const location = useLocation()
  const isHomePage = location.pathname === '/'

  // État pour contrôler l'affichage du menu déroulant
  const [showDropdown, setShowDropdown] = useState(false) // Menu déroulant principal
  const [showSubmenu, setShowSubmenu] = useState(false) // Sous-menu pour "Les Troubles auditifs"

  // Gérer l'affichage du sous-menu
  const handleSubmenuHover = () => {
    setShowSubmenu(true)
  }

  const handleSubmenuLeave = () => {
    setShowSubmenu(false)
  }

  // Gérer l'affichage du menu déroulant au survol du lien "Sophrologie"
  const handleDropdownHover = () => {
    setShowDropdown(true)
  }

  // Gérer la fermeture du menu déroulant lorsque le survol se termine
  const handleDropdownLeave = () => {
    setShowSubmenu(false)
    setShowDropdown(false)
  }
  const scrollToSection = (id) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }
  // Fonction pour fermer le menu déroulant et le menu du header
  const closeMenu = () => {
    setShowDropdown(false)
    document.querySelector('.navbar-toggler').click() // Fermer le menu toggle
  }

  return (
    <header className="custom-border border-3 mb-4">
      <nav className="navbar navbar-expand-lg navbar-custom navbar-light d-flex">
        <div className="container-fluid container-header">
          <NavLink
            className="navbar-brand fs-2 d-flex align-items-center"
            to="/"
          >
            <img
              className="logo"
              src={logo}
              alt="logo MaSophroFabienne"
              aria-hidden="true"
            />
            <span className="mt-3">
              {' '}
              <strong>MaSophroFabienne</strong>{' '}
            </span>
          </NavLink>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Menu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto">
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${isHomePage ? 'active' : ''}`}
                  to="/"
                  onClick={closeMenu}
                >
                  Accueil
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${
                    !isHomePage && location.pathname === '/sophrologie'
                      ? 'active'
                      : ''
                  }`}
                  to="/sophrologie"
                  onClick={closeMenu}
                >
                  Sophrologie
                </NavLink>
              </li>
              <li
                className="nav-item"
                onMouseUp={handleDropdownHover}
                onMouseLeave={handleDropdownLeave}
              >
                <NavLink
                  className={`nav-link ${
                    !isHomePage && location.pathname === '/accompagnements'
                      ? 'active'
                      : ''
                  }`}
                  to="/accompagnements"
                >
                  Accompagnements
                </NavLink>
                {/* Menu déroulant */}
                {showDropdown && (
                  <div className="dropdown-menu">
                    <ul className="container-fluid">
                      <li>
                        <NavLink
                          to="/accompagnements#alzheimer"
                          onClick={() => {
                            scrollToSection('alzheimer')
                            closeMenu()
                          }}
                        >
                          La Maladie d'Alzheimer
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accompagnements#parkinson"
                          onClick={() => {
                            scrollToSection('parkinson')
                            closeMenu()
                          }}
                        >
                          La Maladie de Parkinson
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accompagnements#aidant"
                          onClick={() => {
                            scrollToSection('aidant')
                            closeMenu()
                          }}
                        >
                          Le Proche Aidant
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accompagnements#maux-âge"
                          onClick={() => {
                            scrollToSection('maux-âge')
                            closeMenu()
                          }}
                        >
                          Les Maux de l'Âge
                        </NavLink>
                      </li>
                      <li
                        onMouseEnter={handleSubmenuHover}
                        onMouseLeave={handleSubmenuLeave}
                      >
                        Les Troubles auditifs
                        {showSubmenu && (
                          <ul
                            className="dropdown-submenu"
                            onMouseLeave={() => setShowSubmenu(false)} // Ferme le sous-menu au survol
                          >
                            <li>
                              {' '}
                              <NavLink
                                to="/accompagnements#acouphènes"
                                onClick={() => {
                                  scrollToSection('acouphènes')
                                  closeMenu()
                                  setShowSubmenu(false) // Réinitialise l'état
                                }}
                              >
                                Les acouphènes
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/accompagnements#vertiges"
                                onClick={() => {
                                  scrollToSection('vertiges')
                                  closeMenu() // Assure une fermeture complète après le clic
                                  setShowSubmenu(false) // Réinitialise l'état
                                }}
                              >
                                Les vertiges dont la maladie de Ménière
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </li>

                      <li>
                        <NavLink
                          to="/accompagnements#émotions"
                          onClick={() => {
                            scrollToSection('émotions')
                            closeMenu()
                          }}
                        >
                          Les Émotions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accompagnements#stress"
                          onClick={() => {
                            scrollToSection('stress')
                            closeMenu()
                          }}
                        >
                          Le Stress
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accompagnements#sommeil"
                          onClick={() => {
                            scrollToSection('sommeil')
                            closeMenu()
                          }}
                        >
                          Les Troubles du Sommeil
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accompagnements#attention"
                          onClick={() => {
                            scrollToSection('attention')
                            closeMenu()
                          }}
                        >
                          Les Troubles de l’Attention
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/accompagnements#confiance"
                          onClick={() => {
                            scrollToSection('confiance')
                            closeMenu()
                          }}
                        >
                          La Confiance en Soi
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>

              <li className="nav-item">
                <NavLink
                  className={`nav-link ${
                    !isHomePage && location.pathname === '/a-propos'
                      ? 'active'
                      : ''
                  }`}
                  to="/a-propos"
                  onClick={closeMenu}
                >
                  Fabienne
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${
                    !isHomePage && location.pathname === '/tarifs'
                      ? 'active'
                      : ''
                  }`}
                  to="/tarifs"
                  onClick={closeMenu}
                >
                  Tarifs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${
                    !isHomePage && location.pathname === '/contact'
                      ? 'active'
                      : ''
                  }`}
                  to="/contact"
                  onClick={closeMenu}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
