import React, { useState } from 'react';
import '../../styles/sass/components/_reviewCarousel.scss';


const avis = [
  {
    nom: 'Sophie Martin',
    note: 5,
    commentaire: 'Excellente expérience, Fabienne est très à l’écoute. Je recommande vivement !',
  },
  {
    nom: 'Lucas Dupont',
    note: 4,
    commentaire: 'Séance très apaisante. Je reviendrai sans hésiter.',
  },
  {
    nom: 'Emma Bernard',
    note: 5,
    commentaire: 'Un accompagnement de qualité, merci pour votre bienveillance.',
  },
  {
    nom: 'Paul Moreau',
    note: 5,
    commentaire: 'Super expérience de sophrologie, je ressors détendu et confiant.',
  },
  {
    nom: 'Julie Lefèvre',
    note: 4,
    commentaire: 'Très bon accueil et des séances efficaces, je recommande.',
  }
];

const ReviewCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAllReviews, setShowAllReviews] = useState(false);

  const nextReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % avis.length);
  };

  const prevReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + avis.length) % avis.length);
  };

  return (
    <div className="review-carousel">
      <h2>Témoignages</h2>
      <div className="platforms">
        <button
          className={showAllReviews ? 'active' : ''}
          onClick={() => setShowAllReviews(!showAllReviews)}
        >
          {showAllReviews ? 'Voir moins' : 'Tous les avis'}
        </button>
      </div>
      <div className="overall-rating">
        <h3>Note globale</h3>
        <p>5 ⭐⭐⭐⭐⭐ (50)</p>
      </div>
      {showAllReviews ? (
        <div className="all-reviews">
          {avis.map((review, index) => (
            <div key={index} className="review-card">
              <h4>{review.nom}</h4>
              <p>{'⭐'.repeat(review.note)}</p>
              <p>{review.commentaire}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="review-display">
          <button onClick={prevReview}>⟨</button>
          <div className="review-card">
            <h4>{avis[currentIndex].nom}</h4>
            <p>{'⭐'.repeat(avis[currentIndex].note)}</p>
            <p>{avis[currentIndex].commentaire}</p>
          </div>
          <button onClick={nextReview}>⟩</button>
        </div>
      )}
      <a href="https://g.page/r/Cd6aYdafw6OmEAE/review" target="_blank" rel="noopener noreferrer">
        <button className="write-review">Écrire un avis</button>
      </a>
    </div>
  );
};

export default ReviewCarousel;
