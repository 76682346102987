import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ImageAcc3 from '../../../assets/group-img.png'
import Meet from '../../../assets/meet-img.png'
import '../../../styles/sass/pages/_accompagnement.scss'
import '../../../styles/sass/pages/_home.scss'
import LoaderSpinner from '../../LoaderSpinner/loaderSpinner'

export default function Sophrologie() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title = 'MaSophroFabienne - Trouvez votre accompagnement personnalisé de sophrologie contre l\'anxiété et le stress'

    // Exemple d'utilisation : simulation d'un chargement asynchrone pendant 3 secondes
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])
  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <main className="d-flex justify-content-center container-fluid responsive-sm">
      <section className="container-fluid main-content">
        <Row className="title-accompagnement-container">
          <h1 className="h1-accompagnement"> La Sophrologie</h1>
        </Row>
      </section>

      <div className="mb-3 ">
        <section className="section-accompagnement container-fluid p-5 background-pink-container">
          <Row className="d-flex flex-column mb-0 justify-content-center container-content">
            <div className="container-image-home-1 mt-5 mb-5">
              <img
                className=" img-fluid-acc"
                src={Meet}
                alt="poignée de main chaleureuse entre un homme et une femme"
              />
            </div>
            <h2 className="sub-title ">
              La 1ère séance est une rencontre entre vous et moi...
            </h2>
            <p className="text-accompagnement">
              <strong>Authentique</strong> et <strong>sans a priori</strong>,
              elle permet de vous exprimer librement dans un espace de{' '}
              <strong>confiance</strong> et de <strong>sécurité</strong>, afin
              d’exposer vos <strong>attentes</strong> et de les situer dans
              votre <strong>contexte personnel</strong>. <br />
              Puis je vous propose un <strong>exercice</strong> pour répondre à
              votre <strong>besoin immédiat</strong>. <br />À l’issue de la
              séance, si vous le souhaitez, nous définissons ensemble un{' '}
              <strong>projet d’accompagnement</strong> pour aller vers l’
              <strong>objectif</strong> que vous avez fixé en réponse à vos{' '}
              <strong>besoins</strong>. <br />
              Les séances durent <strong>1h environ</strong>. <br />
              La durée moyenne d’un <strong>accompagnement</strong> est de{' '}
              <strong>6 à 10 séances</strong>, espacées idéalement d’une{' '}
              <strong>semaine</strong>. <br />
              Je ne me substitue pas à un{' '}
              <strong>professionnel de santé</strong>, j’interviens en{' '}
              <strong>complémentarité</strong>. <br />
              Vous pouvez aussi faire des séances de temps en temps pour
              apprendre à vous <strong>relaxer</strong>. <br />
              Les <strong>techniques</strong> sont <strong>simples</strong>, je
              vous <strong>guide avec la voix</strong> et nous les faisons{' '}
              <strong>ensemble</strong>. <br />
              J’<strong>adapte les mouvements</strong> et les{' '}
              <strong>postures</strong> aux possibles du <strong>moment</strong>
              . <br />
              La séance se termine par un <strong>temps d’échange</strong> sur
              le <strong>vécu perçu</strong>, et invite à
              <strong> l'appropriation</strong> pour vous amener progressivement
              à <strong>l’autonomie</strong>.
            </p>
          </Row>
        </section>
        <div className="cta-buttons d-flex justify-content-center mt-5 mb-5 gap">
          {/* Button CTA */}
          <Link to="/tarifs" className="cta-link">
            Connaître les tarifs
          </Link>
          <Link to="/accompagnements" className="cta-link-pink">
            En savoir plus
          </Link>
        </div>

        <section className="section-accompagnement container-fluid p-5 background-container">
          <Row className="d-flex flex-column mb-0 justify-content-center container-content">
            <div className="container-image-home-1 mt-5 mb-5">
              <img
                className="img-fluid-acc"
                src={ImageAcc3}
                alt="poignée de main chaleureuse entre un homme et une femme"
              />
            </div>
            <h2 className="sub-title">En structure d'accueil</h2>
            <p className="text-accompagnement">
              Animer des séances individuelles, en binôme, ou en groupe sous la
              forme d'un atelier d’environ{' '}
              <strong>12 séances de sophrologie</strong> d’1h hebdomadaire, avec
              un <strong>protocole adapté.</strong> <br />
            </p>
            <p className="text-accompagnement">
              J’interviens sur 2 axes : <br />
              Tout d’abord, <strong>la relaxation.</strong> Apaisés, les
              participants sont plus <br />
              réceptifs à l’expérience proposée : une re-connexion à soi et à ce
              qui se vit dans l’instant présent, sans jugement. <br />
              <strong>L’idée </strong> est de venir stimuler ce qui peut l’être,
              sans les mettre en <br /> difficulté, dans une{' '}
              <strong>
                sollicitation sensorielle et relationnelle, <br /> bienveillante
                et respectueuse.
              </strong>
            </p>
            <p className="text-accompagnement">
              L’<strong>approche sensitive</strong> permet d’éviter l’engrenage
              de la privation <br />
              des perceptions et d’entretenir le lien à soi et à son
              environnement. <br />
              L’<strong>approche capacitaire</strong> mobilise et valorise les{' '}
              <strong>capacités résiduelles.</strong>
            </p>
            <p className="text-accompagnement">
              J’interviens en complémentarité de l’équipe pluridisciplinaire et{' '}
              <strong>je m’adapte au besoin du moment.</strong>
            </p>
          </Row>
        </section>
        <div className="cta-buttons d-flex justify-content-center mt-5 mb-5 gap">
          {/* Button CTA */}
          <Link to="/contact" className="cta-link-pink">
            Demander un devis
          </Link>
          <Link to="/accompagnements" className="cta-link">
            Découvrir ma solution
          </Link>
        </div>
      </div>
    </main>
  )
}
