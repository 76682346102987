import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import './styles/sass/main.scss' 
import App from "./components/Router/App";
import Header from "./components/Layouts/Header/header";
import Footer from "./components/Layouts/Footer/footer";
import ScrollToTop from "./components/ScrollToTop/scrollToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
const maintenanceMode = false; // Mettez ici la valeur correspondant à votre mode de maintenance : si true maintenance activée
// Ajoute ou supprime la classe 'maintenance-mode' du body en fonction de l'état de maintenance
if (maintenanceMode) {
  document.body.classList.add('maintenance-mode');
} else {
  document.body.classList.remove('maintenance-mode');
}
root.render(
  <React.StrictMode>
      <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <ScrollToTop />
      {maintenanceMode ? null : <Header />} {/* Ne rend le header que si maintenanceMode est faux */}
      <App />
      {maintenanceMode ? null : <Footer />} {/* Ne rend le footer que si maintenanceMode est faux */}
    </BrowserRouter>
  </React.StrictMode>
);
